.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Nobel, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

p {
  margin-bottom: 10px;
  font-family: Nobel, sans-serif;
  font-weight: 400;
}

.div-block {
  width: 80vh;
  height: 80vh;
  outline-offset: 0px;
  perspective: 1200px;
  perspective-origin: 30%;
  background-color: #000;
  outline: 6px solid #000;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: none;
}

.top {
  width: 200%;
  height: 50vw;
  transform-style: preserve-3d;
  background-color: #d1d0d0;
  position: absolute;
  top: 0;
  transform: translate3d(-50%, -25vw, -25vw)rotateX(90deg)rotateY(0)rotateZ(0);
}

.right {
  width: 50vw;
  height: 100%;
  transform-style: preserve-3d;
  background-color: #020202;
  position: absolute;
  right: 0;
  transform: translate3d(25vw, 0, -25vw)rotateX(0)rotateY(90deg)rotateZ(0);
}

.div-block-2 {
  width: 25vh;
  height: 100vh;
  perspective: 100vh;
  position: absolute;
  transform: translate(50vw);
}

.bottom {
  width: 160vh;
  height: 50vw;
  transform-style: preserve-3d;
  background-color: #202020;
  background-image: linear-gradient(to right, #000, #19191b 67%, #1b1b1c);
  position: absolute;
  bottom: 0;
  transform: translate3d(-50%, 25vw, -25vw)translate(0)rotateX(90deg)rotateY(0)rotateZ(0);
}

.div-block-3 {
  width: 100%;
  height: 100%;
  perspective: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  transform: translate(0);
}

.coming-soon {
  z-index: 50;
  width: 100%;
  perspective: none;
  color: #d1d0d0;
  transform-style: preserve-3d;
  flex: 0 auto;
  order: -1;
  justify-content: center;
  align-self: center;
  padding: 1% 0%;
  font-family: Nobel, sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 1em;
  display: flex;
  transform: translate3d(0, 0, -73px);
}

.body {
  height: 100vh;
  background-color: #d1d0d0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.div-block-4 {
  width: 80vh;
  height: 10vh;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.shadow {
  z-index: -10;
  width: 80vh;
  height: 50vh;
  filter: blur(20px);
  background-image: radial-gradient(circle farthest-side, #636363, rgba(0, 0, 0, 0));
  position: absolute;
  bottom: 0;
  transform: translate(-73px, 17px);
}

.kessel {
  z-index: 50;
  width: 100%;
  color: #d1d0d0;
  flex: 0 auto;
  order: -1;
  justify-content: center;
  align-self: center;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: Nobel, sans-serif;
  font-size: 50px;
  font-weight: 400;
  line-height: 1em;
  display: flex;
}

.text-block {
  margin-left: -6px;
  font-family: Nobel, sans-serif;
  font-weight: 400;
}

.loader {
  z-index: 100;
  width: 80vh;
  height: 80vh;
  background-color: #d1d0d0;
  position: absolute;
  bottom: 10vh;
}

.center {
  width: 160vh;
  height: 80vh;
  transform-style: preserve-3d;
  background-color: #000;
  background-image: radial-gradient(circle at 100% 100%, #181719, #181719 44%, #000 57%, #000);
  position: absolute;
  transform: translate3d(-50%, 0, -50vw);
}

.layout1_component {
  grid-column-gap: 0rem;
  grid-row-gap: 3rem;
  grid-template-rows: auto;
}

.layout1_component.brick {
  height: 100%;
  min-height: 70vh;
  object-fit: fill;
  transform: translate(-8rem);
}

.layout1_component.brick.reverse {
  transform: translate(8rem);
}

.heading-2 {
  text-transform: none;
  font-family: Nobel, sans-serif;
  font-weight: 300;
  font-size: 38px;
  line-height: 44px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.line-divider {
  width: 100%;
  height: 1px;
  background-color: #e9f0f1;
}

.fs-styleguide-nav {
  z-index: 99999999;
  display: none;
}

.fs-styleguide-nav.other {
  display: block;
}

.nav1_logo-link {
  line-height: 0;
  cursor: pointer;
}


.footer2_social-wrapper {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: grid;
}

.nav1_link {
  padding: .5rem 1rem;
  transition: color .25s;
  cursor: pointer;
}

.nav1_link:hover {
  color: #003844;
}

.nav1_link.w--current {
  color: #0b0b1f;
}

.nav1_link.w--current:hover {
  color: #003844;
}

.nav1_link.mobile {
  display: none;
}

.padding-bottom {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.padding-bottom.padding-large {
  padding-bottom: 3rem;
}

.light {
  color: #0b0b1f;
  font-family: Nobel, sans-serif;
  font-weight: 300;
}

.light.white {
  color: #e9f0f1;
}

.social-icon {
  width: 1rem;
  line-height: 0;
}

.image-1.construction {
  height: 100%;
  background-image: url('../images/Kessel_about_01.png');
  background-position: 50% 0;
  background-size: cover;
  background-attachment: scroll;
  border: 3px #000;
  padding-top: 100%;
}

.image-1.services {
  height: 100%;
  background-image: url('../images/Kessel_services_02.png');
  background-position: 50%;
  background-size: cover;
  background-attachment: scroll;
  border: 3px #000;
}

.image-1.contact {
  height: 100%;
  background-image: url('../images/Kessel_contact_01.png');
  background-position: 50% 30%;
  background-size: cover;
}

.container-large {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.nav1_container {
  width: 100%;
  max-width: 80rem;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.margin-bottom {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.padding-vertical {
  padding-left: 0;
  padding-right: 0;
}

.padding-vertical.padding-large {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.padding-vertical.padding-large.brick {
  outline-offset: 0px;
  object-fit: fill;
  outline: 7px solid #000;
  padding-left: 0;
  transform: translate(8rem);
}

.padding-vertical.padding-large.brick.reverse {
  transform: translate(-8rem);
}

.padding-vertical.padding-large.new-bottom {
  padding-top: 0;
  padding-bottom: 0;
}

.locomotive-scroll {
  max-width: 100vw;
  overflow: hidden;
}

.footer2_logo-link{
  cursor: pointer;
}

.footer2_logo-link.mobile {
  display: none;
}

.fs-styleguide-home {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -4.75rem;
  padding-top: 4.75rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  display: flex;
}

.fs-styleguide-home.main {
  width: 100%;
  max-width: 80rem;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.social-link {
  width: 2.5rem;
  height: 2.5rem;
  color: #0b0b1f;
  background-color: #e9f0f1;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: color .25s;
  display: flex;
}

.social-link:hover {
  color: #003844;
}

.footer2_grid {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  grid-template: "Area"
  / max-content auto auto;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  justify-items: stretch;
  display: grid;
}

.footer2_bottom {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr max-content;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: grid;
}

.section-layout1.brick {
  max-width: 80rem;
  background-color: rgba(0, 0, 0, 0);
  margin: 0 auto 6rem;
  padding-top: 6rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.section-layout1.brick.other {
  margin-top: 0;
  margin-bottom: 0;
}

.section-layout1.brick.last {
  margin-bottom: 0;
}

.section-layout1.brick.gallery {
  padding-left: 0;
  padding-right: 0;
}

.section-layout1.last {
  padding-bottom: 150px;
}

.footer2_bottom-text.text-color-white {
  color: #ebebeb;
}

.button {
  color: #fff;
  background-color: #000;
  border: 2px solid #000;
  flex: none;
  padding: .75rem 2rem;
  font-family: Nobel, sans-serif;
  font-weight: 300;
  transition: background-color .25s;
  cursor: pointer;
}

.button:hover {
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
}

.div-block-6 {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
}

.page-padding {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.page-padding.brick {
  background-color: #1f1e24;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
}

.page-padding.item {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.page-padding.textContainer {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  height: 100%;
}

.page-padding.main {
  height: 65vh;
  background-image: url('../images/Kessel_image_01.png');
  background-position: 50% 90%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.page-padding.main-40 {
  height: 40vh;
  background-color: #cfcfcf;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.page-padding.main-40.project {
  height: 35%;
}

.page-padding.contact {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.page-padding.project-main {
  height: 65%;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.container-small {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.text-align-center {
  text-align: center;
}

.div-block-7 {
  z-index: -10;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(rgba(255, 255, 255, .41), rgba(255, 255, 255, .41)), url('../images/4633756817_9472aa76da_o.jpg');
  background-position: 0 0, 0 0;
  background-size: auto, 750px;
  position: fixed;
}

.footer2_component {
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
}

.menu-icon {
  line-height: 1;
}

.footer2_text.text-color-white {
  color: #ebebeb;
  font-weight: 400;
}

.nav1_component {
  background-color: #fff;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.margin-top {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.title {
  width: 100%;
  font-family: Nobel, sans-serif;
  font-size: 5.5rem;
  font-weight: 300;
}

.layout1_content {
  max-width: 37.5rem;
}

.layout1_content.contact {
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.kessellogo {
  font-size: 6rem;
  font-weight: 400;
}

.kessellogo.small {
  font-size: 2rem;
}

.kessellogo.small.white {
  color: #fff;
}

.footer2_link {
  padding-top: .5rem;
  padding-bottom: .5rem;
  transition: color .25s;
  cursor: pointer;
}

.footer2_link:hover {
  color: #003844;
}

.footer2_link.text-color-white {
  color: #ebebeb;
  font-weight: 400;
}

.footer2_link.text-color-white.mobile {
  display: none;
}

.nav1_menu {
  align-content: space-between;
  align-items: center;
  font-weight: 600;
  display: flex;
}

.nav1_menu.mobile {
  display: none;
}

.footer2_column {
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
  display: flex;
}

.footer2_title {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
}

.footer2_title.text-color-white {
  color: #ebebeb;
  font-weight: 400;
}

.fs-styleguide-nav-fixed {
  z-index: 1001;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.text-size-medium {
  font-size: 1.25rem;
}

.text-size-medium.text-weight-bold.text-color-white {
  color: #ebebeb;
  font-weight: 400;
}

.nav1_button-wrapper {
  border: 0 #000;
  margin-left: 1rem;
}

.nav1_button-wrapper.mobile {
  display: none;
}

.footer2_info {
  max-width: 22.5rem;
  margin-right: 2.5rem;
}

.image-2 {
  width: auto;
  height: 50px;
}

.div-block-8 {
  width: 30%;
  background-color: rgba(29, 29, 29, .77);
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}

.text-block-2 {
  color: #ebebeb;
  font-size: 16px;
  line-height: 24px;
}

.grid {
  height: 100%;
  grid-template: "Area Area Area-2 Area-2"
  / 1fr 1fr 1fr 1fr;
  padding: 20px 30px;
}

.paragraph {
  padding-left: 12px;
  padding-right: 12px;
}

.navlink {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to right, #19191b, #19191b 54%, rgba(255, 255, 255, 0) 60%), url('../images/Kessel_angle_dark.svg');
  background-position: 0 0, 100%;
  background-repeat: repeat, no-repeat;
  background-size: auto, auto;
  margin-top: 20px;
  padding: 9px 53px 9px 15px;
  font-family: Nobel, sans-serif;
  font-weight: 300;
  transition: all .2s cubic-bezier(.215, .61, .355, 1);
  cursor: pointer;
}

.navlink:hover {
  transform: translate(0, -4px);
}

.navlink.submit {
  padding-right: 97px;
  display: flex;
  flex-direction: row;
  background-image: linear-gradient(to right, #19191b, #19191b 65%, rgba(255, 255, 255, 0) 60%), url('../images/Kessel_angle_dark.svg');
}

.navlink.to--contact, .navlink.to--about, .navlink.to--services, .navlink.to--projects {
  justify-content: center;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  display: flex;
}

.navlink.mobile, .navlink.inpage {
  display: none;
}

.paragraph-2 {
  font-family: Nobel, sans-serif;
}

.div-block-9 {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.div-block-9.image {
  background-position: 0 0, 50% 0;
  background-size: auto, cover;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.div-block-9.project {
  justify-content: flex-start;
}

.sub-menu.white {
  color: #ebebeb;
}

.image-3 {
  padding-bottom: 20px;
}

.text-size-small {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Nobel, sans-serif;
  font-size: 20px;
  line-height: 28px;
}

.project-showcase.construction {
  height: 100%;
  background-image: url('../images/Kessel_about_01.png');
  background-position: 50% 0;
  background-size: cover;
  background-attachment: scroll;
  border: 3px #000;
  padding-top: 100%;
}

.project-showcase.services {
  height: 100%;
  background-image: url('../images/Kessel_services_02.png');
  background-position: 50%;
  background-size: cover;
  background-attachment: scroll;
  border: 3px #000;
  padding-top: 100%;
}

.slider {
  flex: 9;
  background-color: rgba(255, 255, 255, 0);
}

.slide {
  width: 49%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: auto;
  margin-right: 2%;
}

.slide-nav {
  bottom: -50px;
}

.left-arrow {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  bottom: 0;
}

.icon {
  align-items: flex-end;
  display: flex;
  bottom: 0;
}

.form-block {
  margin-bottom: 0;
}

.button-kessel-angle-transparent {
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to right, rgba(25, 25, 27, .77), rgba(25, 25, 27, .77) 54%, rgba(255, 255, 255, 0) 60%), url('../images/Kessel_angle_dark_77.svg');
  background-position: 0 0, 100% 0;
  background-repeat: repeat, no-repeat;
  background-size: auto, auto;
  margin-top: 20px;
  padding-right: 53px;
  font-family: Nobel, sans-serif;
  font-weight: 300;
  transition: all .2s cubic-bezier(.215, .61, .355, 1);
}

.button-kessel-angle-transparent:hover {
  transform: translate(0, -4px);
}

.button-kessel-angle-transparent.submit {
  padding-right: 97px;
}

.link-block {
  width: 100%;
  align-items: stretch;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  position: absolute;
  bottom: 0;
}

.div-block-10 {
  width: 100%;
  height: 45px;
  background-image: url('../images/Kessel_angle_dark_77.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
}

.div-block-11 {
  background-image: linear-gradient(rgba(29, 29, 29, .77), rgba(29, 29, 29, .77));
  flex-wrap: nowrap;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  display: flex;
}

.text-block-3 {
  width: 100%;
  color: #f5f5f5;
  white-space: nowrap;
  flex: 0 auto;
  font-family: Nobel, sans-serif;
  font-size: 18px;
  font-weight: 300;
}

.navtext {
  color: #fff;
  cursor: pointer;
}

.utility-page-wrap {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.utility-page-content {
  width: 260px;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.div-block-12 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template: "Area Area Area-2 Area-3" 30%
                 "Area-6 Area-6 Area-4 Area-3" 30%
                 "Area-6 Area-6 Area-5 Area-5" 30%
                 / 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.small-box-part {
  background-color: #fff;
  margin: 1px;
  padding: 10px;
}

.data-title {
  font-family: Nobel, sans-serif;
  font-weight: 300;
}

.project-main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 30%;
  overflow: hidden;
}

.gallery-image {
  width: 100%;
  object-fit: cover;
}

.image-4 {
  width: 100%;
  max-height: 100vh;
  object-fit: scale-down;
}

.wrapper-2 {
  width: 100%;
  padding-bottom: 0;
  overflow: hidden;
}

.list {
  justify-content: flex-start;
  align-items: center;
  padding-left: 4vw;
  padding-right: 4vw;
  display: flex;
  overflow: scroll;
}

.home-slider-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 70vh;
  width: 100%;
  transform: translate(-8rem);
}
.item {
  width: 50vh;
  height: 50vh;
  min-width: 50vh;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
  display: flex;
  position: relative;
}

.item2 {
  width: 60vh;
  height: 60vh;
  min-width: 60vh;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
  display: flex;
  position: relative;
}

.image-13 {
  width: 50vh;
  height: 50vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sliderImage {
  width: 60vh;
  height: 60vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.watermark {
  width: 40px;
  height: 40px;
  filter: invert();
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
}

.slider-button-wrap-2 {
  max-width: 1680px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 8rem 0px 54px;
  display: flex;
  transform: translate(8rem);
  
}

.heading-6 {
  font-size: 1.6rem;
  display: block;
}

.arrow-wrapper-2 {
  justify-content: flex-start;
  align-items: center;
  display: block;
}

.slider-button {
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
}

.rl_gallery21_arrow-icon {
  width: 2.5rem;
  height: 2.5rem;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.podm-wrapper {
  width: 40px;
  height: 40px;
  background-color: #25cf3c;
}

.data-data {
  font-family: Nobel, sans-serif;
}

@media screen and (min-width: 1920px) {
  .layout1_component.brick.reverse {
    transform: translate(8rem);
  }

  .fs-styleguide-nav.other {
    width: 100%;
    flex: 0 auto;
    display: block;
  }

  .nav1_container {
    max-width: 80rem;
  }

  .padding-vertical.padding-large.brick {
    transform: translate(8rem);
  }

  .padding-vertical.padding-large.brick.reverse {
    transform: translate(-8rem);
  }

  .locomotive-scroll {
    max-width: 80rem;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    overflow: visible;
  }

  .fs-styleguide-home.main {
    width: 100%;
  }

  .section-layout1.brick {
    max-width: 80rem;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .page-padding.contact {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }

  .footer2_component {
    width: 100vw;
  }

  .slider {
    width: 80rem;
    height: 50vh;
  }
}

@media screen and (max-width: 991px) {
  .div-block {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    perspective: 893px;
    perspective-origin: 30%;
    transform: none;
  }

  .layout1_component {
    align-items: center;
  }

  .layout1_component.brick {
    align-items: stretch;
  }

  .nav1_link {
    display: none;
  }

  .nav1_link.mobile {
    
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    display: block;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-bottom.padding-large {
    padding-bottom: 1rem;
  }

  .image-1.services {
    padding-top: 49%;
    padding-bottom: 49%;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-vertical.padding-large.footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .fs-styleguide-home.main {
    height: auto;
    min-height: 100vh;
  }

  .footer2_grid {
    grid-template-columns: auto auto auto;
    justify-items: stretch;
  }

  .footer2_bottom {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .page-padding.brick {
    padding-top: 4.75rem;
    padding-bottom: 1rem;
  }

  .page-padding.main-40 {
    height: auto;
  }

  .page-padding.main-40.project {
    height: 100%;
    object-fit: fill;
    overflow: visible;
  }

  .footer2_component {
    max-height: 100vh;
    padding-top: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .menu-button {
    margin-right: -.75rem;
    padding: .75rem;
  }

  .menu-button.w--open {
    color: #0b0b1f;
    background-color: rgba(200, 200, 200, 0);
  }

  .nav1_menu {
    text-align: left;
    background-color: #ebebeb;
    margin-left: 0;
    margin-right: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .nav1_button-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 2.5rem;
  }

  .nav1_button-wrapper.mobile {
    display: block;
  }

  .footer2_info {
    display: block;
  }

  .grid {
    min-height: 35%;
    grid-template: "Area Area"
                   "Area-2 Area-2"
                   / 1fr 1fr;
  }

  .text-size-small {
    font-size: 16px;
  }

  .slide {
    width: 100%;
    margin-right: 0%;
  }

  .div-block-12 {
    grid-template-rows: 33.33% 33.33% 33.33%;
  }

  .heading-6, .arrow-wrapper-2 {
    display: none;
  }

  .data-data {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .div-block {
    width: 80vw;
    height: 80vw;
    perspective: 757px;
  }

  .bottom {
    width: 160vw;
  }

  .coming-soon {
    font-size: 20px;
  }

  .div-block-4 {
    width: 80vw;
    height: 10vw;
    margin-top: 10vh;
  }

  .shadow {
    width: 80vw;
    height: 50vw;
    transform: translate(-20%, -24%);
  }

  .kessel {
    font-size: 35px;
    font-weight: 400;
  }

  .center {
    width: 160vw;
    height: 80vw;
  }

  .layout1_component {
    grid-template-columns: 1fr;
  }

  .layout1_component.brick, .layout1_component.brick.reverse {
    transform: translate(0);
  }

  .fs-styleguide-nav.other {
    width: 100%;
    position: fixed;
  }

  .nav1_logo-link {
    padding-left: 0;
  }

  .nav1_link {
    display: none;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .nav1_link.mobile {
    font-family: Nobel, sans-serif;
    font-size: 18px;
    font-weight: 400;
    display: block;
  }

  .home-slider-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 70vh;
    width: 100%;
    transform: translate(0rem);
  }

  .nav1_link.project, .nav1_link.about, .nav1_link.services {
    display: none;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-vertical.padding-large.brick, .padding-vertical.padding-large.brick.reverse {
    transform: translate(0);
  }

  .padding-vertical.padding-large.brick.slider-wrapper {
    padding-bottom: 3rem;
  }

  .footer2_logo-link {
    display: none;
  }

  .footer2_logo-link.mobile {
    display: inline-block;
  }

  .fs-styleguide-home.main {
    margin-top: -.2rem;
  }

  .footer2_grid {
    grid-template: "."
                   "."
                   / 1fr 1fr;
    justify-items: stretch;
  }

  .footer2_bottom {
    flex-direction: column;
    grid-template-columns: 1fr;
    align-items: flex-start;
    justify-items: start;
  }

  .button {
    border: 2px solid #000;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .page-padding {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .page-padding.main {
    height: 40vh;
  }

  .page-padding.main-40 {
    height: auto;
  }

  .page-padding.main-40.project {
    height: auto;
    min-height: 35%;
  }

  .page-padding.project-main {
    height: 40vh;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .layout1_content {
    max-width: none;
  }

  .footer2_link.text-color-white.services, .footer2_link.text-color-white.about, .footer2_link.text-color-white.project, .footer2_link.text-color-white.contact {
    display: none;
  }

  .footer2_link.text-color-white.mobile {
    display: block;
  }

  .nav1_menu {
    background-color: #ebebeb;
    display: none;
  }

  .nav1_menu.mobile {
    display: block;
  }

  .footer2_title {
    font-size: 1.1rem;
  }

  .text-size-medium {
    font-size: 1rem;
  }

  .nav1_button-wrapper {
    margin-left: 1.25rem;
    display: none;
  }

  .nav1_button-wrapper.mobile {
    display: block;
  }

  .div-block-8 {
    width: 40%;
    padding: 10px 8px;
    font-size: 13px;
    line-height: 16px;
  }

  .text-block-2 {
    font-size: 12px;
    line-height: 16px;
  }

  .grid {
    grid-template: "Area Area"
                   "Area-2 Area-2"
                   ". ."
                   / 1fr 1fr;
  }

  .navlink {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 15px;
  }

  .navlink.to--contact, .navlink.to--about, .navlink.to--services, .navlink.to--projects {
    display: none;
  }

  .navlink.mobile {
    display: block;
  }

  .slider {
    transform: translate(0);
  }
}

@media screen and (max-width: 479px) {
  .div-block {
    perspective: 388px;
  }

  .div-block-4 {
    width: 80vw;
    height: 10vh;
  }

  .shadow {
    width: 80vw;
    height: 50vw;
    margin-bottom: -60%;
    bottom: auto;
    transform: translate(-14%);
  }

  .kessel {
    font-size: 25px;
    line-height: .5em;
  }

  .layout1_component.brick, .layout1_component.brick.reverse {
    transform: translate(0);
  }

  .nav1_link.mobile {
    font-family: Nobel, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-bottom.padding-large {
    padding-bottom: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-vertical.padding-large {
    padding-top: 1.25rem;
    padding-bottom: 1rem;
  }

  .padding-vertical.padding-large.brick {
    outline-offset: 0px;
    outline: 7px #000;
    transform: translate(0);
  }

  .padding-vertical.padding-large.brick.reverse {
    transform: translate(0);
  }

  .home-slider-wrapper{
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
    transform: translate(0rem);
  }

  .slider-button-wrap-2 {
    max-width: 1680px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 8rem 0px 54px;
    display: flex;
    transform: translate(0rem);
    
  }

  .footer2_grid {
    grid-template: "Area Area"
                   "Area-2 Area-3"
                   / .5fr;
    grid-auto-flow: row;
    align-content: stretch;
    justify-items: stretch;
  }

  .footer2_bottom {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .section-layout1.brick {
    padding-left: 0;
    padding-right: 0;
  }

  .page-padding.brick {
    padding-bottom: 0;
  }

  .page-padding.main {
    height: 50vh;
  }

  .page-padding.main-40 {
    display: block;
  }

  .page-padding.project-main {
    height: 50vh;
  }

  .footer2_text.text-color-white {
    font-size: 12px;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .div-block-8 {
    width: 80%;
    padding: 10px 8px;
  }

  .text-block-2 {
    font-size: 12px;
    line-height: 16px;
  }

  .grid {
    padding: 10px 10px 20px;
  }

  .div-block-9 {
    padding-left: 0;
    padding-right: 0;
  }

  .slider {
    transform: translate(0);
  }

  .navtext {
    white-space: nowrap;
  }

  .div-block-12 {
    grid-template-rows: minmax(33.33%, 1fr) minmax(33.33%, 1fr) minmax(33.33%, 1fr);
  }

  .item {
    width: 92vw;
    height: 92vw;
    min-width: 92vw;
  }

  .image-13 {
    width: 100%;
    height: 100%;
  }
}

#w-node-_448e504a-da6e-e2f0-2d26-4c9599f0a6a9-518797e0, #w-node-_6ad9bf5b-28b3-8a8a-f887-fe951080f4e1-518797e0, #w-node-_7de6e9d1-be85-84e2-6936-396aa7a8f34d-518797e0, #w-node-_583d8b36-deee-6aa5-bf73-8bea264c0c13-518797e0 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_610038d2-cca1-843f-1cad-84b222e37526-518797e0, #w-node-_610038d2-cca1-843f-1cad-84b222e37533-518797e0 {
  align-self: start;
  justify-self: end;
}

#w-node-_610038d2-cca1-843f-1cad-84b222e37545-518797e0 {
  align-self: stretch;
  justify-self: end;
}

#w-node-_610038d2-cca1-843f-1cad-84b222e3756e-518797e0 {
  justify-self: end;
}

#w-node-f8920c95-7122-aea2-2563-df91807dc6d2-1c6a971a {
  grid-area: Area;
}

#w-node-_1aa16f71-2a72-c355-a65f-1ff539d5eacf-1c6a971a {
  grid-area: Area-2;
}

#w-node-d4836ad3-1f58-3d36-60c4-fb0c51ec14ed-1c6a971a {
  grid-area: Area;
}

#w-node-_4cfc4adc-b77c-9f91-57cc-f46a3193331a-1c6a971a {
  grid-area: Area-2;
}

#w-node-_47d5e485-a911-f1d1-c15e-8b41c47550e4-1c6a971a {
  grid-area: Area-3;
}

#w-node-ffc9b7c1-680a-28f4-9d19-5f0b49f005ab-1c6a971a {
  grid-area: Area-4;
}

#w-node-e50d0b0c-9c73-17ed-7f50-0480e2cbd6d7-1c6a971a {
  grid-area: Area-5;
}

#w-node-d138576c-3773-a884-1ffd-bca579a048b6-1c6a971a {
  grid-area: Area-6;
}

#w-node-f8920c95-7122-aea2-2563-df91807dc782-1c6a971a {
  justify-self: end;
}

@media screen and (max-width: 991px) {
  #w-node-_610038d2-cca1-843f-1cad-84b222e3756e-518797e0, #w-node-f8920c95-7122-aea2-2563-df91807dc782-1c6a971a {
    order: -9999;
    grid-area: span 1 / span 3 / span 1 / span 3;
    justify-self: start;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_610038d2-cca1-843f-1cad-84b222e3757c-518797e0, #w-node-f8920c95-7122-aea2-2563-df91807dc78f-1c6a971a {
    justify-self: start;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_610038d2-cca1-843f-1cad-84b222e37555-518797e0 {
    grid-area: Area-2;
  }

  #w-node-_5d913721-7acf-c376-7ccb-cc2aeb19cc04-518797e0 {
    grid-area: Area-3;
    justify-self: start;
  }

  #w-node-_610038d2-cca1-843f-1cad-84b222e3756e-518797e0 {
    grid-area: Area;
  }

  #w-node-f8920c95-7122-aea2-2563-df91807dc764-1c6a971a {
    grid-area: Area-2;
  }

  #w-node-f8920c95-7122-aea2-2563-df91807dc777-1c6a971a {
    grid-area: Area-3;
    justify-self: start;
  }

  #w-node-f8920c95-7122-aea2-2563-df91807dc782-1c6a971a {
    grid-area: Area;
  }
}


@font-face {
  font-family: 'Nobel';
  src: url('../fonts/Nobel-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nobel';
  src: url('../fonts/Nobel-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Nobel';
  src: url('../fonts/Nobel-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Nobel';
  src: url('../fonts/Nobel-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}